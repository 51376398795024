import styled, { css } from 'styled-components';
import {Dropdown, DropdownItem, Modal} from "react-bootstrap";
import { DropdownButton } from 'react-bootstrap';

const StyledModal = styled(Modal)`
@media (min-width: 576px){
  .modal-dialog {
    max-width:100% !important;
  }}
  .modal-dialog {
    width: ${({width}) => width ? width : '485px'};
    border-radius: 8px;
    background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%);
    margin: 80px auto;
    border: none;
    @media (min-width: 576px){
      .modal-dialog {
        max-width:100% !important;
      }}
    .modal-content {
      height: 100%;
      width:100%;
      border: none;
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    }
    .close {
      margin-top: -30px;
    }
    ${'' /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  display: flex;
  padding: 15px 12px;
  border-bottom: none;
  // background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
  background-color: ${({bgcolor}) => bgcolor ? '#fff' : 'linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%)'};
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  align-items: center;
  height: 50px;
  border-bottom: ${({bgcolor}) => bgcolor ? '1px solid #005C871A' : 'none'};
  > span {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    color: #0D4270;
    text-decoration: underline;
    text-transform: uppercase;
  }
`;
const StyledBody = styled(Modal.Body)`
height: 100%;
width: 100%;
padding:0px 25px !important;
>div:first-child{
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  >div{
    margin: 0;
    padding: 12px 0;
    >button{
      margin-top: 5px;
      font-size: 16px;
      color: #3A3A3A;
      height: 50px
    }
  }
}

>div:nth-child(2){
  padding: 0;
  margin-top: 15px;
  >div:nth-child(2){
    // >div:nth-child(2){
    //   display: none;
    // }
  }
}

>div:last-child{
  padding: 0;
  margin-top: 0px;
}

.add-post-box{
  width: 100%;
  height: 60px;
  background: rgba(156, 156, 156, 0.1);
  border: 1px solid #005C874D;
  border-radius: 4px;
  margin-top: 25px;
  >div{
    .box-title{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #005c87;
    }
  }
}

.btn-title-main{
  text-align: center;
  display: flex;
  justify-content: center;
  .post-btn{
    background: ${({background}) => background ? '#005c87' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
    border-radius: 3px;
    width: 100%;
    height: 50px;
    margin-top: 25px;
    border: none;
    cursor: pointer;
    margin-bottom: 25px;
    opacity: ${({active}) => active ? '' : 0.5};
    cursor: ${({active}) => active ? 'pointer' : 'not-allowed !important'};
  
    .btn-title{
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
  >img{
    margin-left:15px;
  }
}

.post-btn{
  background: ${({background}) => background ? '#005c87' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-radius: 3px;
  width: 100%;
  height: 50px;
  margin-top: 25px;
  border: none;
  cursor: pointer;
  margin-bottom: 25px;
  opacity: ${({active}) => active ? '' : 0.5};
  cursor: ${({active}) => active ? 'pointer' : 'not-allowed !important'};

  .btn-title{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

`;
const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(248,168, 135, 0.1);
  padding: 40px;
  border-radius: 6px;
  @media (max-width: 400px) {
    padding: 10px;
  }
  
  > span {
    text-align: center;
    font-size: 16px;
    color: #FD7175;
    font-family: 'Rubik-Regular';
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;
const TipsButton = styled.button`
  width: 65%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #FD7175;
  border-radius: 6px;
  > span {
    color: #FFFFFF;
    font-family: 'Rubik-Medium';
    text-transform: uppercase;
    font-size: 18px;
  }
`;

const CloseIcon = styled.div`
    width: ${({width}) => width ? '' : '22%'};
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const HeaderText = styled.div`
    font-family: Rubik-Medium;
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #005c87;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SendIcon = styled.div`
    width: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
`;

const TopSection = styled.div`
  display: flex;
  >div:nth-child(2){
    width: 80%;
  }
`;

const MiddleSection = styled.div``;

const CommentSection = styled.div`
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 6px 6px 0 0;
`;

const PeerButton = styled.div`
    width: 100%;
    height: 49px;
    margin-bottom: 10px;
    background: #F9F9F9;
    border-radius: 6px;
    display: flex;
    >img{
        width: 20%;
    }
    >div{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const AddRecognition = styled.div``;
const WhoRecognition = styled.div``;
const WhatRecognition = styled.div``;

const TitleContainer = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '30px 38px 30px 40px' };
  margin: ${({ margin }) => margin};
  > div {
    width: 100%;
    position: relative;
    padding: 12px 10px;
    width: 100%;
    text-align: left;
    margin-top: 15px;
    color: rgb(153,153,153);
    text-transform: capitalize;
    &:active, &:hover, &:focus, &:active:focus {
      outline: none;
      background-color: white;
      border-color: #ccc;
      box-shadow: none;
      color: rgb(153,153,153);
    }
    .dropdown-menu{
      width : 100%;
      height: 200px;
      overflow: hidden scroll;
      z-index: 9999991;
      top: 90%;
    }
    >ul{
      width : 100%;
      >li{
        padding: 3px 20px;
        margin-top: 5px;
        >a{
          background: #F9F9F9;
          border-radius: 3px;
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          border: 1px solid rgba(156, 156, 156, 0.4);
          border-radius: 6px;
          >div:first-child{
            width: 10%;
            display: flex;
            flex-direction: column;
          }
          >div:nth-child(2){
            width: 90%;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #005c87;
          }
        }
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    width: 50%;
  }
  @media (max-width: 1000px){
    width: 100%;
  }


  .dropdown-menu>.active>a{
    background:rgba(247, 245, 235, 0.50)
  }
`;

const ActivityDropdown = styled(DropdownButton)`
  padding: 12px 15px;
  width: 100%;
  text-align: left;
  margin-top: ${({marginTop}) => marginTop ? marginTop : '15px'};
  color: rgb(153,153,153);
  text-transform: capitalize;
  background: rgba(156, 156, 156, 0.1);
  >span{display:none}

  .dropdown-menu>.active>a{
    background:rgba(247, 245, 235, 0.50)
  }


  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    color: rgb(153,153,153);
  
  }


  >div{
    >ul{
      width : 100%;
      >li{
        padding: 3px 20px;
        margin-top: 10px;
        >a{
          background: #F9F9F9;
          border-radius: 3px;
          height: 39px;
          width: 100%;
          display: flex;
          align-items: center;
          >div:nth-child(2){
            width: 90%;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #3A3A3A;
          }
        }
      }
    }
  }
`;

const PeerRecognitionNewDropdown = styled(Dropdown)`
  >.dropdown-toggle.show {
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    background: rgba(156, 156, 156, 0.1) !important;
  }
  button {
    width: 100%;
    text-align: left;
    margin-top: 3px;
    color: rgb(153, 153, 153);
    border-color: rgb(204, 204, 204);
    text-transform: capitalize;
    background: rgba(156, 156, 156, 0.1);
    &:hover {
      outline: none;
      background-color: white;
      box-shadow: none;
      color: rgb(153, 153, 153);
    }
    &:hover,
    &:focus:active,
    &:active,
    &:active:focus {
      background: rgba(156, 156, 156, 0.1) !important;
    }
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    width: 100%;
    height: 200px;
    overflow: hidden scroll;
    z-index: 9999991;
    top: 90%;
    .dropdown-item {
      width: auto;
    }
  }
  .dropdown-item {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    background: rgb(249, 249, 249);
    height: 50px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid rgba(156, 156, 156, 0.4);
    border-radius: 6px;
    &:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
`;

// created new component for "Give Recognition" form under "Social Feeds" with updated react-bootstrap version
const NewActivityDropdown = styled(DropdownButton)`
  .dropdown-menu {
    max-height: 300px;
  }
  button {
    width: 100%;
    background: rgba(156, 156, 156, 0.1);
    border: 1px solid rgb(204, 204, 204) !important;

    ::after {
      content: none;
    }
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      ${"" /* background: rgba(156, 156, 156, 0.1); */}
      background: rgb(249, 249, 249);
    }
  }
`;

const NewCustomMenuItem = styled(DropdownItem)`
  padding: 3px 20px;
  margin-top: 5px;
  background: rgb(249, 249, 249);
  height: 50px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgba(156, 156, 156, 0.4);
  border-radius: 6px;
  img {
    width: 25px !important;
    margin-right: 10px;
    width: 100%;
    margin-right: 10px;
  }
  .customMenuItemTitle {
    width: 90%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgb(58, 58, 58);
  }
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: rgb(249, 249, 249);
  }
`;

const MiddleSection2 = styled.div`
    padding: 20px 38px 30px 40px;
    >div:nth-child(2){
      // border: 1px solid #D8D8D8;
      // box-sizing: border-box;
      // border-radius: 3px;
      margin-top: 4px;
      width: 100%;
      padding: 10px 0px;
        >div:nth-child(2){
          width: 100%;
          // background:rgba(156,156,156,0.1);
          textarea{
            border: none !important;
          // background-color:rgba(156,156,156,0.1);
            color: #005c87;
            ::placeholder {
              color: #005C8799;
            } 
        
            :-ms-input-placeholder {
              color: #005C8799;
            }
        
            ::-ms-input-placeholder {
              color: #005C8799;
            } 
          }
         
          >div:first-child{
            height: 100%;
            >textarea{
              overflow: hidden;
              padding: 0;
              display: flex;
              width: 100%;
              height: ${({border}) => border ? '17px' : '40px'};
              border: ${({border}) => border ? border : '1px solid #D8D8D8'} !important;
              color: #005c87;
              ::placeholder {
                color: #005C8799;
              } 
          
              :-ms-input-placeholder {
                color: #005C8799;
              }
          
              ::-ms-input-placeholder {
                color: #005C8799;
              } 
            }
            >textarea:focus, textarea:hover {       
              outline: none !important;
            }
          }
      }
    }
`;

const FieldTitle2 = styled.div`
  letter-spacing: 0px;
    line-height: 24px;
    color: #005c87;
    font-family: 'Rubik-Regular';
    font-size: 18px;
    .important{
      color:rgb(253, 113, 117);
      font-size: 18px;
    }
`;

const ImgWrap = styled.div`
  width: 25px !important;
  // height: 25px;
  margin-right: 10px;
  color: #9c9c9c;
  display: relative;
  ${'' /* margin: ${({ margin }) => margin}; */}
  // > img {
  //   width: 100%;
  //   height: 100%;
  //   margin-right: 10px;
    color: #005C8799;
    font-size:16px;
    line-height:22px;
    display:relative;
    margin: ${({margin}) => margin};
    >img{
      width: 100%;
      height: 100%;
      margin-right: 10px;
    }
    >span{
      position:absolute;
      right:10px
    }

`;

const NewImgWrap = styled.div`
  width: 25px !important;
  // height: 25px;
  margin-right: 10px;
  color: #9c9c9c;
  display: relative;
  top : 0;
    color: #005C8799;
    font-size:16px;
    line-height:22px;
    display:relative;
    margin: ${({margin}) => margin};
    >img{
      width: 100%;
      height: 100%;
      margin-right: 10px;
    }
    >span{
      position:absolute;
      right:10px
    }

`;

const ContentWrap = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #3A3A3A;
  font-family: Rubik-Medium;
  font-weight:500px;
`;

const ContentWrapPeer = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: #3A3A3A;
  font-family: Rubik-Medium;
  font-weight:500px;
`

const CustomMenuItem2 = styled.div`
border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    position: relative;
    padding: 0 0 10px 0;
    width: 100%;
    text-align: left;
    margin-top:${(mt)=>mt?`15px !important`:0};
    margin-top: 15px;

    color: rgb(153,153,153);
    text-transform: capitalize;
    >div:first-child{
      >img{
        height: 23px;
        width: 19px;
      }
    }
    >div:nth-child(2){
      ${'' /* padding-left: 10px; */}
      padding-top: 0px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #005c87;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
`

const FieldTitle = styled.span`
  font-size: 18px;
  margin-bottom : ${({ mb }) => mb? mb:0};
  letter-spacing: 0px;
  line-height: 24px;
  color: ${({color}) => color ? color :'#005c87'};
  font-family: "Rubik-Regular";
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  .important{
    color:rgb(253, 113, 117);
    font-size: 18px;
  }
`;
const UploadImage2 = styled.label`
  // width: 100%;
  // border: 1px solid #EAEAEA;
  // border-radius: 4px;
  // padding: 11px 25px;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // position: relative;
  // margin-bottom: 20px;
  cursor: pointer;
  border: none;
  >img{
    width: 24px;
    height: 24px;
    // margin-right: 18px;
  }
  >span{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
  }
  input{
    position: absolute;
  }
  [hidden] {
    display: none !important;
  }
`;

const EmojiViewer = styled.label`
  cursor: pointer;
  border: none;
  margin-right: 10px;
`;

const ImageSection = styled.div`
    width: 100%;
  
    .imageContainer {
      width: 100%;
      min-height: 181px;
      height:auto;
      padding:15px;
      flex-wrap:wrap;
      display: flex;
      // position: absolute;
      // margin-bottom: 20px;
      justify-content: flex-start;
      right: 0px;
      top: 110px;
      // padding-left: 15px;
      // padding-bottom: 5px; 
      align-items: center;
      overflow: hidden;
      background: rgba(156, 156, 156, 0.05);
      border: 1px solid rgba(156, 156, 156, 0.4);
      border-radius: 6px;
      .imageDiv{
        height:150px;
        width:149px;
        position:relative;
        margin-bottom:15px;
        margin-right: 25px;
        .remove{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }}
    }
`;

const UploadImage3 = styled.label`
  width: 150px;
  height:150px;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: relative;
  margin-bottom: 15px;
  margin-right: 0px;
  cursor: pointer;
  border: none;
  background-color:#005C871A;
  border: 1px dashed rgba(156, 156, 156, 0.4);
  border-radius: 6px;
  >img{
    width: 50px;
    height: 50px;
    // margin-right: 18px;
  }
  >span{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
  }
  input{
    position: absolute;
  }
  [hidden] {
    display: none !important;
  }
`;

const UploadImages = styled.label`
  // width: 100%;
  // border: 1px solid #EAEAEA;
  // border-radius: 4px;
  // padding: 11px 25px;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // position: relative;
  // margin-bottom: 20px;
  cursor: pointer;
  border: none;
  >img{
    width: 40px;
    height: 40px;
    // margin-right: 18px;
  }
  >span{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
  }
  input{
    position: absolute;
  }
  [hidden] {
    display: none !important;
  }
`;

const ImageSections = styled.div`
    width: 100%;
    .imageContainer {
      width: 100%;
      display: flex;
      position: relative;
      margin-bottom: 20px;
      justify-content: center;
      >img{
        width: 100%;
        max-width: 450px;
        height: auto;
      }
      .remove{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
    }
`;

export {ImageSection,UploadImage3,UploadImage2,
  StyledModal, StyledHeader, StyledBody, ContentContainer, ButtonContainer, TipsButton, CloseIcon, HeaderText, SendIcon, TopSection, MiddleSection, CommentSection, PeerButton, AddRecognition, WhoRecognition, WhatRecognition,
  TitleContainer, ActivityDropdown , MiddleSection2, FieldTitle2, ImgWrap, ContentWrap, CustomMenuItem2, FieldTitle, UploadImages, ImageSections, EmojiViewer, NewActivityDropdown, NewCustomMenuItem, PeerRecognitionNewDropdown, ContentWrapPeer, NewImgWrap
}
